import React from "react";
import { Link } from "react-router-dom";
import { Button, Header, Container, Image, Icon } from "semantic-ui-react";
import { AppComponent } from "../../util/AppComponent";

// CSS
import "./Home.css";

export default class Home extends AppComponent {

  getIntroImageProperties = () => {
    if (this.isMobileScreen(1040)) {
      return {
        src: "/assets/introduction_435.jpg",
        size: "large"
      }
    } else {
      return {
        src: "/assets/introduction_978.jpg",
        size: "big"
      }
    }
  };

  render() {
    const introductionSection = {
      style: {
        backgroundImage: "url('/assets/introduction_background.png')",
        backgroundRepeat: "no-repeat"
      },
      container: {
        header: <div>Zubní ordinace <br></br><span className="emphasis">B-smiledent</span></div>,
        content: <div><p> V zubní ordinaci B-smiledent Vás o?et?í mladé ?eské léka?ky, které mají bohaté zku?enosti a propojení na Stomatochirurgickou ambulanci V?eobecné fakultní nemocnice v Praze. Stomatologická ordinace B-smiledent nabízí kvalitní a dostupnou pé?i o Vá? úsm?v. Dorozumíme se s Vámi v ?e?tin?, angli?tin? i n?m?in?. Postaráme se o v?echny v?kové kategorie - od prvních náv?t?v s mlé?nými zoubky, p?es záchovnou a preventivní stomatologii a? po protetickou rehabilitaci chrupu. Ná? tým Vám poskytne bezbolestné o?et?ení s d?razem na nejmodern?j?ími postupy a skv?lé technické vybavení. Spolupracujeme se zdravotními poji??ovnami.</p><Button color="pink" size="huge" as={Link} to={"/kontakt#contactForm"} name="/kontakt" onClick={this.props.handleItemClick}>Objednat se</Button></div>
      }
    };
    const sections = [
      {
        header: {
          icon: "location arrow",
          content: "Kudy k nám?",
        },
        headerContainer: {
          content: <p><b>Ordinace B-smiledent s.r.o.</b><br />Sou?ástí Stomatologie V Rovinách <br />V Rovinách 1169/56, 140 00 Praha 4</p>
        },
        containers: [
          {
            className: "justify small",
            header: "Lokace",
            content: <p> Ordinace je sou?ástí Stomatologie V Rovinách, le?í na Kav?ích horách na rozhraní moderních obytných i administrativních budov vznikajících v okolí zastávky metra Pankrác a klasickou zástavbou le?ící na pravém b?ehu Vltavy v okolí Podolí a ?lutých lázní. </p>
          }, {
            className: "justify small",
            header: "MHD",
            content: <p>Nejbli??í zastávky autobusu jsou Kav?í hory - jen 3 minuty p??ky od nás (linky 134, 188, 904) a Nové Podolí asi 8 minut p??ky (linka 118). <br /> <br />Nejbli??í zastávka metra je Pankrác, co? je hezkou procházkou p?es Centrální park Pankrác jen asi 13 minut p??ky, nebo se m??ete p?iblí?it linkami autobusu 134 a 188.</p>
          }, {
            className: "justify small",
            header: "Autem",
            content: <p>Ordinace je lehce p?ístupná jak z centra Prahy, tak z p?ilehlého okolí autem. P?ímo p?ed budovou ordinace je mo?né zaparkovat na modrých parkovacích zónách (40 K?/hod), dal?í bezplatná stání jsou cca 200 m od nás v ulici Nad Peka?kou, p?ípadn? 250 m vzdálené parkovi?t? Milevská (fialová zóna 20 K?/hod).</p>
          }
        ],
        additional: <iframe title="google-maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.044462681373!2d14.426868915600323!3d50.04799837942187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b940cce36ac55%3A0xb27d20bd9dbdffd4!2sV%20Rovin%C3%A1ch%201169%2F56%2C%20140%2000%20Praha%204!5e0!3m2!1scs!2scz!4v1654870160434!5m2!1scs!2scz" width="100%" height="400" style={{ border: 0 }} loading="lazy"></iframe>
      }, {
        header: {
          icon: "envelope",
          content: "Kontaktujte nás",
        },
        containers: [
          {
            className: "justify small fixed",
            content: <p> Zubní ordinace B-smiledent s.r.o. je sou?ástí Stomatologie V Rovinách. <br /><br />
              V Rovinách 1169/56 <br /> 140 00 Praha 4 <br /><br /> <b><a href="tel:00420602200202">+420 602 200 202</a></b> <br /> <b><a href="mailto:info@bsmiledent.cz">info@bsmiledent.cz</a></b> <br /> </p>
          }, {
            className: "justify corners small fixed",
            header: "Ordina?ní doba",
            content: (
              <p>
                <b><span className="emphasis">Pond?lí</span></b>: 7:30 - 12:30 a 13:30 - 18:30 <br />
                <b><span className="emphasis">Úterý</span></b>: 7:30 - 15:00 <br />
                <b><span className="emphasis">St?eda</span></b>: 7:30 - 15:30 <br />
                <b><span className="emphasis">?tvrtek</span></b>: 7:30 - 15:30 <br />
                <b><span className="emphasis">Pátek</span></b>: 7:30 - 14:00 <br />
              </p>
            ),
          }
        ]
      }
    ];
    const image = this.getIntroImageProperties();

    return (
      <div className="aih-page home">

        <section className={"aih-section aih-introduction"}>
          <div className="aih-image-container" style={introductionSection.style}>
            <div>
              <Image src={image.src} size={image.size} />
            </div>

            <Container text>
              <Header as="h1">{introductionSection.container.header}</Header>
              {introductionSection.container.content}
            </Container>
          </div>
        </section>

        <div className="aih-text-sections">
          {sections.map((section, index) => (
            <section key={index} className="aih-section aih-text-section">

              <Header as="h1">
                <Icon name={section.header.icon} />
                <Header.Content>{section.header.content}</Header.Content>
              </Header>

              {section.headerContainer !== undefined ? (
                <Container textAlign="center" text>
                  {section.headerContainer.content}
                </Container>
              ) : undefined}

              <div className="aih-containers">
                {section.containers.map((container, index) => (
                  <Container key={index} className={container.className} text>
                    <Header as="h2">{container.header}</Header>
                    {container.content}
                  </Container>
                ))}
              </div>

              {section.additional !== undefined ? (
                <div className="aih-additional">
                  {section.additional}
                </div>
              ) : undefined}

            </section>
          ))}
        </div>
      </div >
    );
  }
}