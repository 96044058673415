import { Component, Fragment } from "react";
import { Link, BrowserRouter, Routes, Route } from "react-router-dom";
import { Menu, Icon, Image, Header, List, Message } from "semantic-ui-react";
import { ScrollToTop } from "./util/AppComponent";

// Componets
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Price from "./components/Price/Price";
import Contact from "./components/Contact/Contact";

// CSS
import "semantic-ui-css/semantic.min.css"
import "./util/Global.css";
import "./App.css";

export default class App extends Component {

  constructor(props) {
    super(props);

    // Get information from storage
    let cookiesAllowed = null;
    try {
      cookiesAllowed = localStorage.getItem("cookiesAllowed");
    } catch (ex) { }

    this.state = {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      activeMenuUrl: window.location.pathname,
      mobileMenuDisplay: "none",
      visibleCookies: cookiesAllowed != null ? false : true,
    };

    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleItemClick = (event, { name }) => {
    this.setState({
      activeMenuUrl: name,
      mobileMenuDisplay: "none"
    })
  }

  updateDimensions = () => {
    this.setState({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  }

  showMobileMenu = event => {
    const { mobileMenuDisplay } = this.state;
    if (mobileMenuDisplay === "none") {
      this.setState({ mobileMenuDisplay: "block" })
    } else {
      this.setState({ mobileMenuDisplay: "none" })
    }
  }

  getLogoProperties = () => {
    if (this.state.screenWidth <= 850) {
      return {
        to: "/",
        src: "/assets/logo_140.png",
        size: "small"
      }
    } else {
      return {
        to: "/",
        src: "/assets/logo_313.png",
        size: "medium"
      }
    }
  };

  getMenuProperties = () => {
    if (this.state.screenWidth <= 1000) {
      return {
        fixed: "right",
        compact: true,
        vertical: true,
        className: "mobile",
        style: { display: this.state.mobileMenuDisplay }
      }
    } else {
      return {
        size: "huge",
        color: "pink",
        secondary: true,
      }
    }
  };

  handleDismiss(e) {
    this.setState({ visibleCookies: false })

    // Try to save to local storage
    try {
      localStorage.setItem("cookiesAllowed", true);
    } catch (ex) { }
  }

  render() {
    const { screenWidth, activeMenuUrl, visibleCookies } = this.state;

    // Header
    const headerlogoProperties = this.getLogoProperties();
    const HeaderMenuProperties = this.getMenuProperties();
    const headerMenu = {
      items: [
        {
          to: "/",
          content: "Na?e ordinace",
        }, {
          to: "/onas",
          content: "O nás",

        }, {
          to: "/sluzby",
          content: "Poskytované slu?by",

        }, {
          to: "/cenik",
          content: "Ceník",

        }, {
          to: "/kontakt",
          content: "Kontakt",
        }
      ],
      mobile: {
        close: "Zav?ít",
      }
    };

    // Page routing
    const routing = [
      ["/", <Home screenWidth={screenWidth} isLoaded={true} handleItemClick={this.handleItemClick} />],
      ["/onas", <About screenWidth={screenWidth} isLoaded={true} />],
      ["/sluzby", <Services screenWidth={screenWidth} isLoaded={true} />],
      ["/cenik", <Price screenWidth={screenWidth} isLoaded={true} />],
      ["/kontakt", <Contact screenWidth={screenWidth} isLoaded={true} />]
    ];

    // Footer 
    const footerMenu1 = {
      header: "Menu",
      items: [
        {
          to: "/",
          content: "Na?e ordinace",
        }, {
          to: "/onas",
          content: "O nás",
        }, {
          to: "/sluzby",
          content: "Poskytované slu?by",
        }, {
          to: "/cenik",
          content: "Ceník",
        }, {
          to: "/kontakt",
          content: "Kontakt",
        }
      ]
    };
    const footerMenu2 = {
      header: "Kontakt",
      items: [
        {
          as: Link,
          to: { pathname: "/kontakt", state: { fromDashboard: true } },
          content: "Objednat se",
        }, {
          icon: "location arrow",
          content: "V Rovinách 1169/56, 140 00 Praha 4",
        }, {
          as: "a",
          icon: "phone",
          href: "tel:00420602200202",
          content: "+420 602 200 202",
        }, {
          as: "a",
          icon: "envelope",
          href: "mailto:info@bsmiledent.cz",
          content: "info@bsmiledent.cz",
        },
      ]
    };
    const footerLogo = {
      textContent: "Zubní ordinace B-smiledent s.r.o.",
      logo: {
        to: "/",
        src: "/assets/logo_140.png",
        size: "small",
      }
    };

    // Cookies
    const cookiesMessage = "Pou?íváním t?chto webových stránek souhlasíte s vyu?itím základních cookies ve va?em webovém prohlí?e?i pro správný chod aplikace.";

    return (
      <Fragment>
        <BrowserRouter>
          <ScrollToTop />

          {/* Menu */}
          <div className="aih-header">
            <div className="aih-inner-header">

              <Image
                src={headerlogoProperties.src}
                size={headerlogoProperties.size}
                as={Link}
                to={headerlogoProperties.to} />

              {screenWidth <= 1000 ? <Icon name="list" size="large" onClick={this.showMobileMenu} /> : ""}

              <Menu {...HeaderMenuProperties} >
                {headerMenu.items.map((item, index) => (
                  <Menu.Item
                    as={Link}
                    key={index}
                    to={{ pathname: item.to, state: { fromDashboard: true } }}
                    name={item.to}
                    active={activeMenuUrl === item.to}
                    onClick={this.handleItemClick}
                    content={item.content}
                  />
                ))}

                {screenWidth <= 1000 ? (
                  <Menu.Item key={0} name={"close"} content={headerMenu.mobile.close} onClick={this.showMobileMenu} />)
                  : ""}

              </Menu>
            </div>
          </div>

          {/* Page content */}
          <Routes>
            {routing.map((page, index) => (
              <Route exact path={page[0]} element={page[1]} key={index} />
            ))}
            <Route path="/*" element={
              <div className="aih-error-area">
                <span>404: Stránka nenalezena</span>
              </div>
            } />
          </Routes>

          {/* Footer */}
          <footer>
            <div className="aih-footer">

              <div className="aih-menu-list">
                <div className="menu">
                  <Header as="h3">{footerMenu1.header}</Header>
                  <List>
                    {footerMenu1.items.map((item, index) => (
                      <List.Item
                        as={Link}
                        key={index}
                        to={{ pathname: item.to, state: { fromDashboard: true } }}
                        name={item.to}
                        active={activeMenuUrl === item.to}
                        onClick={this.handleItemClick}
                        content={item.content} />
                    ))}
                  </List>
                </div>

                <div className="menu">
                  <Header as="h3">{footerMenu2.header}</Header>
                  <List>
                    {footerMenu2.items.map((item, index) => (
                      <List.Item
                        as={item.as}
                        key={index}
                        icon={item.icon}
                        href={item.href}
                        to={item.to}
                        onClick={this.handleItemClick}
                        content={item.content} />
                    ))}
                  </List>
                </div>
              </div>

              <div className="aih-logo-footer">
                <div className="aih-logo">
                  <Image
                    src={footerLogo.logo.src}
                    size={footerLogo.logo.size}
                    as={Link}
                    to={footerLogo.logo.to}
                  />
                  <a target="_blank" rel="noreferrer" href="https://instagram.com/bsmiledent">
                    <Icon name="instagram" size="large" />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/bsmiledent">
                    <Icon name="facebook square" size="large" />
                  </a>
                </div>
                <span>{footerLogo.textContent}</span>
              </div>

            </div>
          </footer>

          {/* Cookies */}
          {visibleCookies ? (
            <div className="aih-cookies-area">
              <Message
                onDismiss={this.handleDismiss}
                content={cookiesMessage}
              />
            </div>
          ) : ""}
        </BrowserRouter>
      </Fragment >
    );
  }
}