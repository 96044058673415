import React from "react";
import { List, Header, Image, Container, Icon } from "semantic-ui-react";
import { AppComponent } from "../../util/AppComponent";

// CSS
import "./About.css";

export default class About extends AppComponent {

  getImageProperties = () => {
    if (this.isMobileScreen(1040)) {
      return {
        doctor1: {
          src: "/assets/nemcova_435.jpg",
          size: "large",
        },
        doctor2: {
          src: "/assets/hornova_435.jpg",
          size: "large",
        },
      }
    } else {
      return {
        doctor1: {
          src: "/assets/nemcova_978.jpg",
          size: "big",
        },
        doctor2: {
          position: "after",
          src: "/assets/hornova_978.jpg",
          size: "large",
        },
      }
    }
  };

  render() {
    const headerContent = "O nás";
    const doctor1 = {
      header: "MDDr. Barbora N?mcová",
      content: (
        <div>
          <span className="subtitle emphasis">{<div><b>vedoucí léka?ka</b></div>}</span>
          <List>
            <List.Item>- 2020 - promoce na 1. léka?ská fakult? Univerzity Karlovy, obor zubní léka?ství, Praha</List.Item>
            <List.Item>- 09/2020- 06/2022 praktická zubní léka?ka v soukromé zubní praxi v Praze</List.Item>
            <List.Item>- od 09/2020 Stomatologická klinika VFN a 1. LF UK, Klinika ústní, ?elistní a obli?ejové chirurgie - zubní léka?ka na chirurgické ambulanci</List.Item>
            <List.Item>- 10/2018 - 02/2019 studium na Universität Rostock, Spolková republika Ne?mecko, obor Zahnmedizin</List.Item>
            <List.Item>- pravidelná ú?ast na stomatologických kongresech a praktických workshopech v ?eské republice i v zahrani?í se zam??ením na celý rozsah stomatologické pé?e</List.Item>
          </List>
          <b className="emphasis">Jazyky</b>: ?eský, anglický, n?mecký
        </div>
      ),
    };
    const doctor2 = {
      header: "MDDr. Kate?ina Hornová",
      content: (
        <div>
          <List>
            <List.Item>- 2021 - promoce na 1. léka?ská fakult? Univerzity Karlovy, obor zubní léka?ství, Praha</List.Item>
            <List.Item>- 09/2021- 08/2022 praktická zubní léka?ka v soukromé zubní praxi v Praze</List.Item>
            <List.Item>- od 09/2021 Stomatologická klinika VFN a 1. LF UK, Klinika ústní, ?elistní a obli?ejové chirurgie - zubní léka?ka na chirurgické ambulanci</List.Item>
            <List.Item>- pravidelná ú?ast na stomatologických kongresech a praktických workshopech v ?eské republice i v zahrani?í se zam??ením na celý rozsah stomatologické pé?e</List.Item>
          </List>
          <b className="emphasis">Jazyky</b>: ?eský, anglický
        </div>
      ),
    };
    const doctorImages = this.getImageProperties();

    return (
      <div className="aih-page about">
        <div className="aih-image-sections">

          <section className="aih-section aih-image-section">
            <Header as="h1">
              <Icon name="doctor" />
              <Header.Content>{headerContent}</Header.Content>
            </Header>

            <div className="aih-image-container">
              <div>
                <Image src={doctorImages.doctor1.src} size={doctorImages.doctor1.size} />
              </div>

              <Container text>
                <Header as="h2">{doctor1.header}</Header>
                {doctor1.content}
              </Container>
            </div>
          </section>

          <section className="aih-section aih-image-section">
            <div className="aih-image-container">
              {doctorImages.doctor2.position !== "after" ? (
                <div>
                  <Image src={doctorImages.doctor2.src} size={doctorImages.doctor2.size} />
                </div>
              ) : undefined}
              
              <Container text>
                <Header as="h2">{doctor2.header}</Header>
                {doctor2.content}
              </Container>

              {doctorImages.doctor2.position === "after" ? (
                <div>
                  <Image src={doctorImages.doctor2.src} size={doctorImages.doctor2.size} />
                </div>
              ) : undefined}

            </div>
          </section>

        </div>
      </div>
    );
  }
}