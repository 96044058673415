import React from "react";
import { Form, Button, Message, Header, Container, Icon } from "semantic-ui-react";
import { AppComponent } from "../../util/AppComponent";

// CSS
import "./Contact.css";

export default class Contact extends AppComponent {

  constructor(props) {
    super(props);

    this.state = {
      message: "",
      // Form values
      email: "",
      name: "",
      phone: "",
      type: "",
      area: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    const messageTexts = {
      success: "E-mail byl úsp??n? odeslán.",
      failed: "N?co se nepovedlo! E-mail nelze odeslat."
    }

    // Send a request
    let url = "/web/mail/send/form";
    let body = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      type: this.state.type,
      area: this.state.area,
    };
    this.sendRequest(url, "POST", body)
      .then((data) => {
        if (data.error) {
          this.setState({
            message: <Message negative icon="mail" header={messageTexts.failed} />
          });
        } else {
          this.setState({
            name: "",
            email: "",
            phone: "",
            type: "",
            area: "",
            message: <Message positive icon="mail" header={messageTexts.success} />
          });
        }
      });
  }

  render() {
    const { name, email, phone, type, area, message } = this.state

    const contactSection = {
      header: {
        content: "Kontaktujte nás",
      },
      containers: [
        {
          className: "justify small fixed",
          content: <p> Zubní ordinace B-smiledent s.r.o. je sou?ástí Stomatologie V Rovinách. <br /><br />
            V Rovinách 1169/56 <br /> 140 00 Praha 4 <br /><br /> <b><a href="tel:00420602200202">+420 602 200 202</a></b> <br /> <b><a href="mailto:info@bsmiledent.cz">info@bsmiledent.cz</a></b> <br /> </p>
        }, {
          className: "justify corners small fixed",
          header: "Ordina?ní doba",
          content: (
            <p>
              <b><span className="emphasis">Pond?lí</span></b>: 7:30 - 12:30 a 13:30 - 18:30 <br />
              <b><span className="emphasis">Úterý</span></b>: 7:30 - 15:00 <br />
              <b><span className="emphasis">St?eda</span></b>: 7:30 - 15:30 <br />
              <b><span className="emphasis">?tvrtek</span></b>: 7:30 - 15:30 <br />
              <b><span className="emphasis">Pátek</span></b>: 7:30 - 14:00 <br />
            </p>
          ),
        }
      ]
    };

    const formSection = {
      header: {
        content: "Chcete se k nám objednat?",
      },
      headerContainer: {
        content: <p>Na detailech a termínu se s vámi domluvíme po e-mailu i po telefonu. Pro urychlení nám prosím vypl?te kontaktní formulá? ní?e na stránce a my se Vám ozveme zpátky.</p>
      }
    };

    const form = {
      nameField: {
        label: "Jméno a p?íjmení",
        placeholder: "Jméno a p?íjmení"
      },
      emailField: {
        label: "E-mail",
        placeholder: "E-mail"
      },
      phoneField: {
        label: "Telefon",
        placeholder: "Telefon"
      },
      typeField: {
        label: "Mám zájem objednat se na",
        placeholder: "Mám zájem objednat se na",
        options: [
          { key: 1, text: "Vstupní vy?et?ení (jsem nový pacient)", value: "Vstupní vy?et?ení (jsem nový pacient)" },
          { key: 2, text: "Preventivní prohlídku", value: "Preventivní prohlídku" },
          { key: 3, text: "Mám akutní problém ", value: "Mám akutní problém" },
          { key: 4, text: "Plombu dle zhotoveného lé?ebného plánu (a? po vstupní prohlídce)", value: "Plombu dle zhotoveného lé?ebného plánu (a? po vstupní prohlídce)" },
          { key: 5, text: "Na vytr?ení zubu", value: "Na vytr?ení zubu" },
          { key: 6, text: "Jiný výkon", value: "Jiný výkon" },
        ]
      },
      areaField: {
        label: "Zde m??ete popsat více",
        placeholder: "Zde m??ete popsat více"
      },
      button: {
        content: "Odeslat formulá?"
      },
    };

    const infoSection = {
      containers: [
        {
          header: "Co kdy? se mi termín nehodí?",
          content: <p>Pokud se Vám domluvený termín nehodí, ur?it? nám o tom dejte co nejd?íve v?d?t. Rádi vám nabídneme jiný termín. Vá? termín pak m??e vyu?ít n?kdo jiný, kdo na na n?j t?eba práv? ?eká. Neomluvený termín nebo náv?t?va omluvená na poslední chvíli pro nás obvykle znamená promarn?ný ?as. <br /> <br />Omluvit se m??ete <b>nejpozd?ji 48 hod p?ed termínem telefonicky</b>, nebo emailem.  V p?ípad?, ?e telefon v pracovní dob? nezvedáme, v?nujeme se pacient?m v ordinaci, d?kujeme za trp?livost.</p>
        }, {
          header: "Co kdy? m? n?co rozbolí?",
          content: <p>V p?ípad? akutních obtí?í nás kontaktujte telefonicky v ordina?ní dob?, p?ípadn? skrze email. V p?ípad?, ?e telefon v pracovní dob? nezvedáme, v?nujeme se pacient?m v ordinaci. V?dy se sna?íme vá? problém ?e?it v co nejkrat?í mo?né dob? a je-li to mo?né, v jedné náv?t?v?.</p>
        }
      ]
    };

    return (
      <div className="aih-page contact">

        <section className={"aih-section aih-text-section centered"}>
          <Header as="h1">
            <Icon name="hospital" />
            <Header.Content>{contactSection.header.content}</Header.Content>
          </Header>

          <div className="aih-containers">
            {contactSection.containers.map((container, index) => (
              <Container key={index} className={container.className} text>
                <Header as="h2">{container.header}</Header>
                {container.content}
              </Container>
            ))}
          </div>
        </section>

        <section id="contactForm" className={"aih-section aih-text-section centered"}>
          <Header as="h1">
            <Header.Content>{formSection.header.content}</Header.Content>
          </Header>

          <Container textAlign="center" text>
            {formSection.headerContainer.content}
          </Container>
        </section>

        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            label={form.nameField.label}
            placeholder={form.nameField.placeholder}
            name="name"
            value={name}
            onChange={this.handleChange}
            required={true}
          />
          <Form.Input
            label={form.emailField.label}
            placeholder={form.emailField.placeholder}
            name="email"
            value={email}
            onChange={this.handleChange}
            required={true}
          />
          <Form.Input
            label={form.phoneField.label}
            placeholder={form.phoneField.placeholder}
            name="phone"
            value={phone}
            onChange={this.handleChange}
            required={true}
          />
          <Form.Select
            label={form.typeField.label}
            placeholder={form.typeField.placeholder}
            name="type"
            value={type}
            onChange={this.handleChange}
            required={true}
            options={form.typeField.options}
            fluid
          />
          <Form.TextArea
            label={form.areaField.label}
            placeholder={form.areaField.placeholder}
            name="area"
            value={area}
            onChange={this.handleChange}
            required={true}
          />
          <Button color="pink" size="large" type="submit">{form.button.content}</Button>
        </Form>
        {message}

        <section className={"aih-section aih-text-section onsides"}>
          <div className="aih-containers">
            {infoSection.containers.map((container, index) => (
              <Container key={index} className="justify small" text>
                <Header as="h2">{container.header}</Header>
                {container.content}
              </Container>
            ))}
          </div>
        </section>
      </div>
    );
  }
}