import { Component, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Loader } from "semantic-ui-react"

// ------------------------------------------
// AppComponent v1.0.0
// ------------------------------------------

export class AppComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: props.isLoaded === true ? true : false,
      url: props.url !== undefined ? props.url : window.location.pathname,
    };
  }

  componentDidMount() {
    if (!this.state.isLoaded) {
      this.load();
    }
  }

  render() {
    const { error, isLoaded } = this.state;
    if (error) {
      return this.showErrorMessage(error.message);
    } else if (!isLoaded) {
      return this.showLoader();
    } else {
      return this.showErrorMessage("An unknown error has occurred.");
    }
  }

  load() {
    if (this.state.isLoaded) {
      this.setState({
        isLoaded: false,
      });
    }
    this.getData(this.state.url);
  }

  async sendRequest(url, method, body = undefined, binaryBody = false) {
    if (binaryBody) {
      if (body) {
        return await this._invokeRequest(url, method, { "Accept": "application/json", }, body);
      } else {
        return { error: "Empty body", message: "Request body is missing", }
      }
    } else {
      return await this._invokeRequest(url, method, { "Accept": "application/json", "Content-Type": "application/json", },
        body ? JSON.stringify(body) : undefined);
    }
  }

  async _invokeRequest(url, method, headers, body = undefined) {
    if (url && method && headers) {
      return await fetch("/api/v1" + url, {
        method: method,
        headers: headers,
        body: body,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
            return { error: response.error, message: response.message, };
          } else {
            return response;
          }
        }, (error) => {
          return { error: "An error occurred", message: error.message, };
        });
    } else {
      return { error: "Empty url, method or headers", message: "Url, method or headers is missing", }
    }
  }

  getData(url, key) {
    this.sendRequest(url, "GET")
      .then((data) => {
        if (data.error) {
          this.setState({
            isLoaded: true,
            error: data,
          });
        } else {
          this.dataObtained(data, key);
        }
      });
  }

  dataObtained(data, key) {
    this.setState({
      isLoaded: true,
      data: data,
    });
  }

  showErrorMessage(message) {
    return <div className="aih-error-area">Error: {message}</div>;
  }

  showLoader() {
    return <Loader inline="centered" active />;
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value, });
  }

  handleCheck = (event, { name, checked }) => {
    this.setState({ [name]: checked, });
  }

  handleFileChange = (event, { name }) => {
    this.setState({ [name]: event.target.files[0], });
  };

  isMobileScreen(width = 850) {
    return this.props.screenWidth <= width;
  }

  isLogged() {
    return this.props.logged !== undefined;
  }

  getDataValue(value) {
    return value !== null && value !== undefined ? value : "";
  }

  cut(text, max) {
    return (text.length > max) ? text.slice(0, max) + "..." : text;
  }

  formatDate(date) {
    const dateToFormat = new Date(date);
    return dateToFormat.toUTCString();
  }

  getSearchParam(queryString, param) {
    const params = new URLSearchParams(queryString);
    return params.get(param);
  }
}

export function ScrollToTop() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      // Scroll to hash
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return null;
}
