import React from "react";
import { Table, Header, Icon, Container } from "semantic-ui-react";
import { AppComponent } from "../../util/AppComponent";

// CSS
import "./Price.css";

export default class Price extends AppComponent {

  render() {
    const priceSection = {
      header: {
        content: "Ceník",
      },
      headerContainer: {
        content: <p>Ceník vybraných stomatologických výkon?</p>,
      },
      table: {
        className: "aih-price-table",
        rows: [
          {
            content: (
              <Table.Row key={1}>
                <Table.Cell><b>Komplexní vy?et?ení stomatologem p?i registraci pacienta (dosp?lý i dít?)</b><br />* Vstupní prohlídka je hrazena ze zdravotního poji?t?ní.</Table.Cell>
                <Table.Cell width="2" textAlign="right">0 K?*</Table.Cell>
              </Table.Row>
            )
          }, {
            content: (
              <Table.Row key={2}>
                <Table.Cell><b>Preventivní prohlídka (dosp?lý i dít?)</b><br />* Preventivní prohlídka je hrazena ze zdravotního poji?t?ní dle indikace o?et?ujícího léka?e 1x nebo 2x za rok.</Table.Cell>
                <Table.Cell width="2" textAlign="right">0 K?*</Table.Cell>
              </Table.Row>
            )
          }, {
            content: (
              <Table.Row key={3}>
                <Table.Cell><b>Sestavení a tisk individuálního lé?ebného plánu</b><br />Ka?dému pacientovi individuáln? vytvo?ený plán - pro lep?í p?ehled stavu Va?eho chrupu a naplánování o?et?ení.<br /> *Plán se nezhotovuje d?tským pacient?m a pacient?m s kompletní ztrátou chrupu.</Table.Cell>
                <Table.Cell width="2" textAlign="right">1000 K?*</Table.Cell>
              </Table.Row>
            )
          }, {
            content: (
              <Table.Row key={4}>
                <Table.Cell><b>Fotokompozitní výpl? (bílá)</b><br />Vysoce estetická bílá výpl?. V?etn? nasazení kofferdamu.</Table.Cell>
                <Table.Cell width="2" textAlign="right">1400 - 5500 K?</Table.Cell>
              </Table.Row>
            )
          }, {
            content: (
              <Table.Row key={5}>
                <Table.Cell><b>Celokeramická korunka</b><br />Celokeramická korunka, která je vlastnostmi velmi podobná zubu. Cena v?etn? otiskování a fixace.</Table.Cell>
                <Table.Cell width="2" textAlign="right">10000 - 11400 K?</Table.Cell>
              </Table.Row>
            )
          }, {
            content: (
              <Table.Row key={6}>
                <Table.Cell><b>Definitivní o?et?ení ko?enových kanálk?</b><br />O?et?ení a zapln?ní kanálku s vyu?itím nejmodern?j?ího vybavení a zv?t?ovacích lupových brýlí (strojové o?et?ení, zapln?ní teplou guttaperchou). V?etn? kofferdamu. Cena se odvíjí od jednotlivých zub?. Definitivnímu o?et?ení nej?ast?ji p?edchází provizorní o?et?ení.</Table.Cell>
                <Table.Cell width="2" textAlign="right">7800 - 10000 K?</Table.Cell>
              </Table.Row>
            )
          }, {
            content: (
              <Table.Row key={7}>
                <Table.Cell><b>O?et?ení d?tského pacienta - zvý?ená ?asová dotace</b><br />Kvalitní a bezbolestné o?et?ení d?tských pacient? je ?asov? náro?né. Chceme, aby k nám d?ti chodily rády, tudí? jim dop?áváme pot?ebný ?as. Proto za vybrané výkony (nap?. zhotovení skloionomerních výplní) je ú?tována úhrada za zvý?enou ?asovou dotaci na zákrok.</Table.Cell>
                <Table.Cell width="2" textAlign="right">1500 K?</Table.Cell>
              </Table.Row>
            )
          }, {
            content: (
              <Table.Row key={8}>
                <Table.Cell><b>Konzultace</b><br />Pokud nejste na?ím pacientem a chcete cokoliv zkonzultovat, objednejte se.</Table.Cell>
                <Table.Cell width="2" textAlign="right">1150 - 3350 K?</Table.Cell>
              </Table.Row>
            )
          },
        ]
      },
      additional: (<i>Uvedené ceny jsou orienta?ní a kone?ná cena u jednotlivých výkon? se pohybuje v ur?itém rozmezí dle rozsahu, ?asové a materiálové náro?nosti zákroku. Uvádíme pouze nej?ast?j?í výkony, kompletní ceník je k nahlédnutí na vy?ádání v ordinaci. <b> Cenu za va?e o?et?ení vám v?dy sd?líme p?edem a v?echny pot?ebné výkony konkrétn? pro Vás budou popsány ve va?em Lé?ebném a cenovém plánu.</b></i>),
    };
    const infoSection = {
      header: {
        content: "Smlouvy s poji??ovnami"
      },
      content: (
        <div>
          <p>Chceme poskytovat kvalitní a dostupnou stomatologickou pé?i. A i p?es to, ?e zdravotní poji?t?ní nehradí (a ani nep?ispívá) na v?t?inu výkon? provád?ných v moderní stomatologii, <b>máme uzav?ené smlouvy se v?emi zdravotními poji??ovnami v ?R </b>(V?eobecnou zdravotní poji??ovnou (VZP, kód 111), Vojenskou zdravotní poji??ovnou ?eské republiky (VoZP, kód 201), ?eskou pr?myslovou zdravotní poji??ovnou (?PZP, kód 205), Oborovou zdravotní poji??ovnou (OZP, kód 207), Zam?stnaneckou poji??ovnou ?koda (ZP?, kód 209) a Zdravotní poji??ovnou ministerstva vnitra (ZPMV?R, kód 211) a Revírní bratrskou pokladnou, zdravotní poji??ovnou (RBP, kód 213)).</p>
          <p>Pro na?e pacienty p?edstavuje uzav?ení smluv finan?ní úsporu v oblasti prevence, kdy jsou ze zdravotního poji?t?ní hrazeny vstupní prohlídky a pravidelné preventivní prohlídky a také úhradu (plnou ?i ?áste?nou) vybraných základních výkon? (nap?. anestezie, RTG snímky, základní snímatelné protézy?).</p>
        </div>
      ),
    };

    return (
      <div className="aih-page price">

        <section className={"aih-section"}>
          <Header as="h1">
            <Icon name="list alternate outline" />
            <Header.Content>{priceSection.header.content}</Header.Content>
          </Header>

          <Container textAlign="center" text>
            {priceSection.headerContainer.content}
          </Container>

          <Table className={priceSection.table.className}>
            <Table.Body>
              {priceSection.table.rows.map((row) => (
                row.content
              ))}
            </Table.Body>
          </Table>

          <div className="aih-additional">
            {priceSection.additional}
          </div>
        </section>

        <section className={"aih-section aih-info"}>
          <Header as="h2">
            <Header.Content>{infoSection.header.content}</Header.Content>
          </Header>

          {infoSection.content}
        </section>
      </div>
    );
  }
}