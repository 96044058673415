import React from "react";
import { Header, Container, Icon } from "semantic-ui-react";
import { AppComponent } from "../../util/AppComponent";

// CSS
import "./Services.css";

export default class Services extends AppComponent {

  render() {
    const sections = [
      {
        header: {
          content: "Poskytovaná pé?e",
        },
        headerContainer: {
          content: <p><i>Na?ím cílem je v?em pacient?m nabídnout osobní p?ístup, p?íjemné prost?edí a kvalitní pé?i. Nechceme, abyste se náv?t?v u zuba?e obávali, proto si na ka?dého pacienta necháváme <b>dostatek ?asu</b> a v?e Vám ?ádn? vysv?tlíme. Moderní stomatologie vy?aduje dostatek ?asu, proto pacienty objednáváme na konkrétní termíny a sna?íme se tím minimalizovat Va?e ?ekání.</i></p>,
        },
        containers: [
          {
            className: "justify small",
            content: <p>Na va?í první náv?t?v? Vás poprosíme o vypln?ní <b>Anamnestického dotazníku</b>, abychom m?li informace ohledn? Va?eho celkového zdravotního stavu, který m??e mít vliv i na o?et?ení u nás v ordinaci.</p>
          }, {
            className: "justify small",
            content: <p>Základem zdravého úsm?vu jsou pravidelné a komplexní prohlídky Va?eho chrupu a celé dutiny ústní. Jen díky tomu jsme schopni odhalit v?as vá?né potí?e, které pak vedou ke komplikovanému ?e?ení, které je ?asto i velice finan?n? nákladné.</p>
          }, {
            className: "justify small",
            content: <p>Moderní stomatologie si ji? dávno nevysta?í pouze s vy?et?ením sondou a zrcátkem, ale jak vstupní, tak preventivní prohlídky jsou dopln?ny <b>RTG snímky</b> va?eho chrupu a ?elistí. P?i vstupním vy?et?ení Vám bude zhotoven velký panoramatický RTG tzv. OPG a bude dopln?no n?kolika malými intraorálními RTG.</p>
          }, {
            className: "justify small padded",
            header: "SANACE ZUBNÍHO KAZU",
            headerAs: "h2",
            content: <p>P?i nutnosti zub ?vyvrtat? p?istupujeme ke ka?dému zubu maximáln? ?etrn? a co nejmén? invazivn?. K o?et?ení nej?ast?ji pou?íváme moderní <b>fotokompozitní výpln? - bílé plomby</b>, které jsou svojí vazbou k tvrdým zubním tkáním pro zachování vitality zubu vhodné a zárove? jsou vysoce estetické. Aby byla výpl? zhotovena kvalitn? a vydr?ela dlouho, provádíme o?et?ení ?asto za pomoci zv?t?ovací optiky a jeliko? je zásadní suché pracovní pole, sna?íme se ka?dé o?et?ení provád?t se speciální blánou kofferdamem, která nám udr?í sucho a zárove? je pro pacienta o?et?ení komfortn?j?í, jeliko? mu nic nete?e do krku, nevadí mu v ústech savka a nepolyká p?ípadné zbytky odvrtávaných amalgám? ?i infikovaných tvrdých zubních tkání.</p>
          }, {
            className: "justify small padded",
            header: "ENDODONTICKÉ O?ET?ENÍ KO?ENOVÝCH KANÁLK?",
            content: <p>V p?ípad? hlubokého kazu zasahujícího a? do zubní d?en?, je nutné ko?enové kanálky ?ádn? o?et?it. Endodontické o?et?ení je mnohdy technicky velmi náro?né, na na?em pracovi?ti k zaji?t?ní úsp??né a kvalitní terapie pou?íváme <b>nejmodern?j?í p?ístroje a zv?t?ovací optiku</b>.  Samoz?ejmostí v na?í ordinaci je ú?inné strojové opracování ko?enových kanálk?, p?esné nam??ení pracovní délky a? po konec ko?ene zubu a zapln?ní kanálk? rozeh?átou gutaper?ou. Po endodotickém o?et?ení se na zubu zhotovuje výpl? a v p?ípad? v?t?í ztráty tvrdých zubních tkání zubu je nutné zhotovit nep?ímou protetickou práci (korunku, overaly).</p>
          }, {
            className: "justify small padded",
            header: "EXTRAKCE ZUBU PROSTÁ A CHIRURGICKÁ",
            content: <p>Zub, který ji? nejde jakkoli o?et?it a zachovat v dutin? ústní je indikován k extrakci. Jednodu??í variantou je prostá extrakce zubu, který je celý pro?ezaný do dutiny ústní, slo?it?j?í je pak chirurgická extrakce zubu, který nekouká celý do dutiny ústní. Výkon provádíme <b>nebolestiv? v lokální anestezii</b>, zub vybavujeme co nejmén? invazivn?, po výkonu provedeme v p?ípad? nutnosti za?ití rány vst?ebatelným ?itím a ?ádn? Vás informujeme o pr?b?hu hojení.</p>
          }, {
            className: "justify small padded",
            header: "O?ET?OVÁNÍ D?TÍ",
            content: <div><p>V?echny v?kové kategorie d?tí jsou u nás vítány, ale pro kvalitní rodinnou pé?i vy?adujeme, aby <b>spolu s dít?tem byl v na?í pé?i alespo? jeden z rodi??</b>. Malému pacientovi bude mnohem p?íjemn?j?í, kdy? uvidí, ?e nejde k zuba?i sám, ale i rodi?e mu p?jdou p?íkladem. Doporu?ujeme <b>první náv?t?vu</b> dít?te okolo jednoho roku dít?te, aby se dít? seznámilo s ordinací, povozilo se na k?esle, prozkoumalo v?e, co ho zajímá.</p><p><i>Kvalitní a bezbolestné o?et?ení d?tských pacient? je ?asov? náro?né. Chceme, aby k nám d?ti chodily rády, tudí? jim dop?áváme pot?ebný ?as. Proto za vybrané výkony je ú?tována úhrada za zvý?enou ?asovou dotaci na zákrok.</i></p></div>
          },
        ]
      },
    ];

    return (
      <div className="aih-page services">
        <div className="aih-text-sections onsides">
          {sections.map((section, index) => (
            <section key={index} className="aih-section aih-text-section">

              <Header as="h1">
                <Icon name="medkit" />
                <Header.Content>{section.header.content}</Header.Content>
              </Header>

              <Container textAlign="center" text>
                {section.headerContainer.content}
              </Container>

              <div className="aih-containers">
                {section.containers.map((container, index) => (
                  <Container key={index} className={container.className} text>
                    <Header as="h2">{container.header}</Header>
                    {container.content}
                  </Container>
                ))}
              </div>

            </section>
          ))}
        </div>

      </div>
    );
  }
}